<template>
    <div class="global-alert">
        <v-alert
            v-for="alert in displayedAlerts"
            :key="alert.key"
            :value="!dismissedAlerts.includes(alert.key)"
            type="error"
            class="mb-4"
            dismissible
            @click="onClick($event, alert.key)">
            {{ alert.message }}
        </v-alert>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import alerts from './alerts';

export default {
    data() {
        return {
            // @TODO determine more appropriate server-side solution for alerts
            alerts
        };
    },
    computed: {
        ...mapGetters([
            'userHasRoles'
        ]),
        ...mapState({
            dismissedAlerts: (state) => state.settings.dismissedAlerts
        }),
        displayedAlerts() {
            return this.alerts.filter(alert => {
                
                // If not roles were defined show to all
                if (!alert.roles?.length) {
                    return true;
                }

                return this.userHasRoles(alert.roles);
            });
        }
    },
    methods: {
        ...mapActions([
            'dismissAlert'
        ]),
        onClick(e, key) {
            if (e.target.classList.contains('v-icon')) {
                this.dismissAlert(key);
            }
        }
    }
};
</script>