var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-card',{staticClass:"overall-status flex ma-3 xs12",attrs:{"value":_vm.overallStatus !== 'great'},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Overall Status ")]},proxy:true},{key:"action-buttons",fn:function(){return [_c('action-button',{attrs:{"icon":"refresh"},on:{"click":_vm.recheckAllAlerts}},[_vm._v(" Check all alerts ")]),_c('action-button',{staticClass:"status",attrs:{"icon":"circle","loading":_vm.dealerAlertsLoading,"color":_vm.overallStatusColor}},[_vm._v(" Status: "+_vm._s(_vm.overallStatus)+" ")])]},proxy:true}])},[[(_vm.dealerAlertsLoading)?_c('loader',{staticClass:"loader"}):_c('div',{staticClass:"section alerts"},[_c('p',{staticClass:"section-title"},[_vm._v(" Alerts ")]),_c('table',{staticClass:"alert-listing"},[_c('thead',[_c('tr',[_c('td',[_vm._v("Status")]),_c('td',[_vm._v("Issue")]),_c('td',[_vm._v("Action Needed")]),_c('td',[_vm._v("Re-check Alert")])])]),_c('tbody',[(_vm.formattedAlerts.length == 0)?_c('tr',[_c('td',{staticClass:"no-alerts",attrs:{"colspan":"3"}},[_vm._v(" Everything looks good! ")])]):_vm._e(),_vm._l((_vm.formattedAlerts),function(alert,alertIndex){return _c('tr',{key:alertIndex},[_c('td',{staticClass:"status",class:alert.status},[_c('p',{staticClass:"time-ago"},[_vm._v(" "+_vm._s(alert.time_ago)+" ")]),_c('p',{staticClass:"type"},[_vm._v(" "+_vm._s(alert.status)+" ")])]),_c('td',{staticClass:"issue"},[_c('p',{staticClass:"message"},[_c('remote-content',{attrs:{"value":alert.message,"dealer":_vm.currentDealer,"ticket-data":{
                                        type: 'Other',
                                        subject: alert.type
                                    }}})],1)]),_c('td',{staticClass:"actions"},[_c('ul',_vm._l((alert.actions),function(action,actionIndex){return _c('li',{key:actionIndex},[_c('remote-content-link',{attrs:{"href":action.url,"dealer":_vm.currentDealer,"ticket-data":{
                                            type: 'Other',
                                            subject: alert.type
                                        }}},[_vm._v(" "+_vm._s(action.title)+" ")])],1)}),0)]),_c('td',{staticClass:"recheck"},[_c('action-button',{attrs:{"icon":"refresh","loading":_vm.checkIfAlertIsRefreshing(alert)},on:{"click":function($event){return _vm.recheckAlert(alert)}}},[_vm._v(" Check alert ")])],1)])})],2)]),_c('a',{staticClass:"see-more",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showMore.apply(null, arguments)}}},[_vm._v("See more")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }