<template>
    <styled-card
        class="overall-status flex ma-3 xs12"
        :value="overallStatus !== 'great'">
        <template #heading>
            Overall Status
        </template>
        <template #action-buttons>
            <action-button
                icon="refresh"
                @click="recheckAllAlerts">
                Check all alerts
            </action-button>
            <action-button
                icon="circle"
                class="status"
                :loading="dealerAlertsLoading"
                :color="overallStatusColor">
                Status: {{ overallStatus }}
            </action-button>
        </template>
        <template>
            <!-- <div class="section opportunities">
                <p class="section-title">
                    Opportunities
                </p>
                <div class="opportunity">
                    <p class="relative-date">
                        Today
                    </p>
                    <button
                        class="dismiss"
                        @click="dismissOpportunity">
                        <icon
                            name="close"
                            size="15px" />
                    </button>
                    <p class="description">
                        Activate an on-Facebook AIA playbook to get more lead coverage
                    </p>
                    <a
                        href="#"
                        class="cta">Deploy ON-FACEBOOK AIA</a>
                </div>
                <a
                    href="#"
                    class="see-more"
                    @click.prevent="showMore">See more opportunities</a>
            </div> -->
            <loader
                v-if="dealerAlertsLoading"
                class="loader" />
            <div
                v-else
                class="section alerts">
                <p class="section-title">
                    Alerts
                </p>
                <table class="alert-listing">
                    <thead>
                        <tr>
                            <td>Status</td>
                            <td>Issue</td>
                            <td>Action Needed</td>
                            <td>Re-check Alert</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="formattedAlerts.length == 0">
                            <td
                                class="no-alerts"
                                colspan="3">
                                Everything looks good!
                            </td>
                        </tr>
                        <tr
                            v-for="(alert, alertIndex) in formattedAlerts"
                            :key="alertIndex">
                            <td
                                class="status"
                                :class="alert.status">
                                <p class="time-ago">
                                    {{ alert.time_ago }}
                                </p>
                                <p class="type">
                                    {{ alert.status }}
                                </p>
                            </td>
                            <td class="issue">
                                <p class="message">
                                    <remote-content 
                                        :value="alert.message"
                                        :dealer="currentDealer"
                                        :ticket-data="{
                                            type: 'Other',
                                            subject: alert.type
                                        }" />
                                </p>
                            </td>
                            <td class="actions">
                                <ul>
                                    <li
                                        v-for="(action,actionIndex) in alert.actions"
                                        :key="actionIndex">
                                        <remote-content-link 
                                            :href="action.url"
                                            :dealer="currentDealer"
                                            :ticket-data="{
                                                type: 'Other',
                                                subject: alert.type
                                            }">
                                            {{ action.title }}
                                        </remote-content-link>
                                    </li>
                                </ul>
                            </td>
                            <td class="recheck">
                                <action-button
                                    icon="refresh"
                                    :loading="checkIfAlertIsRefreshing(alert)"
                                    @click="recheckAlert(alert)">
                                    Check alert
                                </action-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <a
                    class="see-more"
                    href="#"
                    @click.prevent="showMore">See more</a>
            </div>
        </template>
    </styled-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RemoteContentLink from '@/components/globals/RemoteContentLink';
import RemoteContent from '@/components/globals/RemoteContent';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import Loader from '@/components/globals/Loader';

import moment from 'moment';
import { TOGGLE_OPPORTUNITY_SLIDEOUT } from '@/store/mutation-types';

export default {
    name: 'OverallStatus',
    components: {
        RemoteContentLink,
        RemoteContent,
        StyledCard,
        ActionButton,
        Loader
    },
    computed: {
        ...mapState({
            dealerAlerts: (state) => state.monitoring.dealerAlerts,
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentDealer: (state) => state.dealer.currentDealer,
            dealerAlertsLoading: (state) => state.monitoring.dealerAlertsLoading,
            refreshingAlerts: (state) => state.monitoring.refreshingAlerts,
        }),
        formattedAlerts() {
            return this.dealerAlerts.map(alert => {
                return {
                    time_ago: moment.utc(alert.created_at.date).fromNow(),
                    status: alert.level,
                    type_id: alert.alert_type_id,
                    message: alert.message,
                    actions: alert.actions,
                    type: alert.alert_type?.display_name || ''
                };
            });
        },
        overallStatusColor() {
            if(this.formattedAlerts.some(alert => alert.status == 'critical')) {
                return 'red';
            } else if(this.formattedAlerts.some(alert => alert.status == 'warning')) {
                return 'orange';
            }
            return 'green';
        },
        overallStatus() {
            if(this.formattedAlerts.some(alert => alert.status == 'critical')) {
                return 'critical';
            } else if(this.formattedAlerts.some(alert => alert.status == 'warning')) {
                return 'warning';
            }
            return 'great';
        }

    },
    methods: {
        ...mapActions([
            'getDealerAlerts',
            'refreshAlert',
        ]),
        dismissOpportunity() {
            console.log('dismissed');
        },
        showMore() {
            this.$store.commit(TOGGLE_OPPORTUNITY_SLIDEOUT, true);
        },
        checkIfAlertIsRefreshing(alert) {
            return this.refreshingAlerts.findIndex(refreshing => refreshing.alertTypeId == alert.type_id) > -1;
        },
        async recheckAlert(alert) {
            await this.refreshAlert({ dealerId: this.currentDealerId, alertTypeId: alert.type_id });
            this.getDealerAlerts(this.currentDealerId);
        },
        async recheckAllAlerts() {
            const requests = this.formattedAlerts.map(alert => this.refreshAlert({ dealerId: this.currentDealerId, alertTypeId: alert.type_id }));
            await Promise.all(requests);
            this.getDealerAlerts(this.currentDealerId);
        }


    }
};
</script>

<style lang="scss">
.loader {
    margin: 0 auto;
}
.overall-status {
    .styled-card-body-inner {
        display: flex;
        flex-direction: row;
    }
    .section {
        padding: 30px 40px;
        .section-title {
            float: left;
            font-size: 14px;
            font-weight: 600;
            padding: 2px 10px;
            text-transform: uppercase;
            color: $white;
            background-color: #00a2ea;
            border-radius: 5px;
        }
    }
    .opportunities {
        width: 30%;
        border-right: #d8d8d8 1px solid;
        font-size: 15px;
        .opportunity {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            float: left;
            padding: 30px;
            border-radius: 10px;
            border: 1px solid #d8d8d8;
            .time-ago {
                font-size: 12px;
                color: #00a2ea;
            }
            .dismiss {
                position: absolute;
                top: 5px;
                right: 8px;
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
            .cta {
                font-size: 13px;
                text-align: center;
                margin: 0 auto;
            }
        }
        .see-more {
            float: right;
            margin-top: 40px;
        }
    }
    .alerts {
        flex: 1;
        .alert-listing {
            float: left;
            width: 100%;
            text-align: left;
            font-size: 12px;
            font-weight: normal;
            border-collapse: collapse;
            .no-alerts {
                text-align: center;
                font-size: 16px;
                padding: 15px;
                font-style: italic;
            }
            td {
                &:nth-child(2) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            thead {
                td {
                    color: #9b9b9b;
                    border-bottom: 1px solid #d8d8d8;
                    &:nth-child(2) {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
            tbody {
                border-bottom: 1px solid #d8d8d8;
                &::before, &::after {
                    content: '';
                    display: block;
                    height: 14px;
                }
                td {
                    vertical-align: middle;
                    padding: 8px 0;
                    p:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
            .status {
                background: transparent !important;
                .type {
                    float: left;
                    padding: 2px 10px;
                    border-radius: 5px;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: $white;
                    margin-top: -10px;
                    margin-bottom: 15px;
                }
                &.critical {
                    .time-ago {
                        color: #f6685f;
                    }
                    .type {
                        background: #f6685f;
                    }
                }
                &.warning {
                    .time-ago {
                        color: #ff914f;
                    }
                    .type {
                        background: #ff914f;
                    }
                }
            }
            .issue {
                .message {
                    max-width: 450px;
                }
            }
            .actions {
                ul {
                    list-style: none;
                    padding-left: 0;
                }
                li {
                    margin: 10px 0;
                }
            }
        }
        .see-more {
            float: right;
            margin-top: 30px;
        }
    }
}
</style>