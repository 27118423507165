<template>
    <styled-card
        id="omni-funnel"
        class="flex ma-3 xs12">
        <template #heading>
            Omni-Channel Advertising Stats
        </template>
        <template #action-buttons>
            <action-button
                class="mr-4"
                icon="pdf"
                @click="showGenerateReportSlideout = true">
                Generate
            </action-button>
            <action-button
                v-if="!userIsClient"
                icon="info"
                href="https://support.buyerbridge.io/knowledge/omnichannel-ad-metrics"
                target="_blank">
                Info
            </action-button>
            <action-button
                v-if="!userIsClient"
                icon="cog"
                :to="{ name: 'dealer' }">
                Account Settings
            </action-button>
        </template>
        <loader v-if="loading" />
        <div
            v-else
            class="metrics">
            <div class="left-pane">
                <div class="platform-selections">
                    <div
                        :class="{ inactive: !dealerHasFacebook }"
                        class="platform-select">
                        <label>
                            <p class="icon">
                                <img src="/img/platforms/meta.svg">
                            </p>
                            <p class="name">
                                Meta
                            </p>
                        </label>
                        <styled-checkbox
                            value="facebook"
                            :checked="platformSelections.includes('facebook')"
                            :disabled="platformSelections.includes('facebook') && platformSelections.length === 1"
                            @change="togglePlatform" />
                    </div>
                    <div
                        :class="{ inactive: !dealerHasSnapchat }"
                        class="platform-select">
                        <label>
                            <p class="icon">
                                <img src="/img/platforms/snapchat.svg">
                            </p>
                            <p class="name">
                                Snapchat
                            </p>
                        </label>
                        <styled-checkbox
                            value="snapchat"
                            :checked="platformSelections.includes('snapchat')"
                            :disabled="platformSelections.includes('snapchat') && platformSelections.length === 1"
                            @change="togglePlatform" />
                    </div>
                    <div
                        :class="{ inactive: !dealerHasTiktok }"
                        class="platform-select">
                        <label>
                            <p class="icon">
                                <img src="/img/platforms/tiktok.svg">
                            </p>
                            <p class="name">
                                TikTok
                            </p>
                        </label>
                        <styled-checkbox
                            value="tiktok"
                            :checked="platformSelections.includes('tiktok')"
                            :disabled="platformSelections.includes('tiktok') && platformSelections.length === 1"
                            @change="togglePlatform" />
                    </div>
                    <styled-tooltip
                        :disabled="!pinterestMetricsRunning.value"
                        position="top">
                        <template #content>
                            <div v-if="pinterestMetricsRunning.status === 'running'">
                                Pinterest stats are currently being populated for this date range.
                                Please check back soon to see your data!
                            </div>
                            <div v-else>
                                Pinterest stats processing failed. Sometimes this can be resolved by clicking retry.
                                If not, click the info icon for additional steps to resolve the issue.
                            </div>
                        </template>
                        <div
                            :class="{ inactive: !dealerHasPinterest }"
                            class="platform-select">
                            <label>
                                <p class="icon">
                                    <icon
                                        v-if="pinterestMetricsRunning.status === 'running'"
                                        class="channel-warning"
                                        size="15"
                                        :color="colors.yellow"
                                        name="indicator-warning" />
                                    <icon
                                        v-if="pinterestMetricsRunning.status === 'failed'"
                                        class="channel-warning"
                                        size="15"
                                        :color="colors.red"
                                        name="indicator-error" />
                                    <img src="/img/platforms/pinterest.svg">
                                </p>
                                <p class="name">
                                    Pinterest
                                </p>
                            </label>
                            <styled-checkbox
                                value="pinterest"
                                :checked="platformSelections.includes('pinterest')"
                                :disabled="platformSelections.includes('pinterest') && platformSelections.length === 1"
                                @change="togglePlatform" />
                        </div>
                    </styled-tooltip>
                </div>
                <funnel
                    :metric="metric"
                    :metrics="metricsTotals"
                    :available-metrics="availableMetrics"
                    @changeMetric="changeMetric" />
                <div
                    v-if="numberOfDays > 90"
                    class="data-errors">
                    <p class="error">
                        We are currently only able to graph up to 90 days of Snapchat data.
                    </p>
                </div>
                <div
                    v-else-if="errorPlatforms.length > 0"
                    class="data-errors">
                    <p class="error">
                        Some or all of the data is not available at this time for the following channels during the specified dates selected:
                    </p>
                    <ul class="platforms">
                        <li
                            v-for="(platform,i) in errorPlatforms"
                            :key="i"
                            class="platform">
                            {{ platform }}
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="
                    right-pane">
                <chart
                    :metric="metric"
                    :metric-series="metricsIntervals"
                    :available-metrics="availableMetrics"
                    :metrics="metrics"
                    @changeMetric="changeMetric" />
            </div>
        </div>
        <div
            v-if="!loading"
            class="channel-totals">
            <table class="channel-table">
                <tr class="header">
                    <td>Channel</td>
                    <td>Impressions</td>
                    <td>CPM</td>
                    <td>Clicks</td>
                    <td>CPC</td>
                    <td>Leads</td>
                    <td>CPL</td>
                    <td>Reports</td>
                </tr>
                <tr
                    v-if="dealerHasFacebook && !facebookMetricsError && metricsAvailableFacebook"
                    class="channel">
                    <td>Meta</td>
                    <td>{{ facebookMetrics.impressions.formatted }}</td>
                    <td>{{ facebookMetrics.cpm.formatted }}</td>
                    <td>{{ facebookMetrics.clicks.formatted }}</td>
                    <td>{{ facebookMetrics.cost_per_click.formatted }}</td>
                    <td>{{ facebookMetrics.leads.formatted }}</td>
                    <td>{{ facebookMetrics.cost_per_lead.formatted }}</td>
                    <td>
                        <action-button
                            icon="report-detailed"
                            :to="{ name: 'facebook-dashboard', params: { dealer_id: dealer.id }}">
                            Show Details
                        </action-button>
                    </td>
                </tr>
                <tr
                    v-if="dealerHasSnapchat && !snapchatMetricsError && metricsAvailableSnapchat"
                    class="channel">
                    <td>Snapchat</td>
                    <td>{{ snapchatMetrics.stats.impressions.formatted }}</td>
                    <td>{{ snapchatMetrics.stats.cpm.formatted }}</td>
                    <td>{{ snapchatMetrics.stats.swipes.formatted }}</td>
                    <td>{{ snapchatMetrics.stats.swipes_cost_per.formatted }}</td>
                    <td>{{ snapchatMetrics.stats.conversion_sign_ups.formatted }}</td>
                    <td>{{ snapchatMetrics.stats.conversion_sign_ups_cost_per.formatted }}</td>
                    <td>
                        <action-button
                            icon="report-detailed"
                            :to="{ name: 'snapchat-dashboard', params: { dealer_id: dealer.id }}">
                            Show Details
                        </action-button>
                    </td>
                </tr>
                <tr
                    v-if="dealerHasTiktok && !tiktokMetricsError && metricsAvailableTiktok"
                    class="channel">
                    <td>TikTok</td>
                    <td>{{ tiktokMetrics.stats.impressions.formatted }}</td>
                    <td>{{ tiktokMetrics.stats.cpm.formatted }}</td>
                    <td>{{ tiktokMetrics.stats.clicks.formatted }}</td>
                    <td>{{ tiktokMetrics.stats.clicks_cost_per.formatted }}</td>
                    <td>{{ tiktokMetrics.stats.online_consult.formatted }}</td>
                    <td>{{ tiktokMetrics.stats.online_consult_cost_per.formatted }}</td>
                    <td>
                        <action-button
                            icon="report-detailed"
                            :to="{ name: 'tiktok-dashboard', params: { dealer_id: dealer.id }}">
                            Show Details
                        </action-button>
                    </td>
                </tr>
                <tr
                    v-if="pinterestMetricsRunning.value || (dealerHasPinterest && !pinterestMetricsError)"
                    class="channel">
                    <td>
                        <div class="channel-name">
                            <styled-tooltip
                                nudge-top="5"
                                :disabled="!pinterestMetricsRunning.value"
                                position="top">
                                <template #content>
                                    <div v-if="pinterestMetricsRunning.status === 'running'">
                                        Pinterest stats are currently being populated for this date range.
                                        Please check back soon to see your data!
                                    </div>
                                    <div v-else>
                                        Pinterest stats processing failed. Sometimes this can be resolved by clicking retry.
                                        If not, click the info icon for additional steps to resolve the issue.
                                    </div>
                                </template>
                                <div class="channel-holder">
                                    <icon
                                        v-if="pinterestMetricsRunning.status === 'running'"
                                        class="mr-2"
                                        size="15"
                                        :color="colors.yellow"
                                        name="indicator-warning" />
                                    <icon
                                        v-if="pinterestMetricsRunning.status === 'failed'"
                                        class="mr-2"
                                        size="15"
                                        :color="colors.red"
                                        name="indicator-error" />
                                    <span>Pinterest</span>
                                </div>
                                <div
                                    class="channel-processing"
                                    :class="{'failed': pinterestMetricsRunning.status === 'failed'}">
                                    <v-progress-circular
                                        v-if="pinterestMetricsRunning.status === 'running'"
                                        class="mr-2"
                                        size="15"
                                        width="2"
                                        color="primary"
                                        indeterminate />
                                    <span v-if="pinterestMetricsRunning.status === 'running'">Stats processing...</span>
                                    <span v-if="pinterestMetricsRunning.status === 'failed'">Stats failed</span>
                                    <div
                                        v-if="pinterestMetricsRunning.status === 'failed'"
                                        class="info-failed">
                                        <a
                                            class="ml-2"
                                            @click="getPinterestStats">retry</a>
                                        <span @click="checkConnection">
                                            <icon
                                                class="ml-2 info-icon"
                                                size="15"
                                                name="indicator-info" />
                                        </span>
                                    </div>
                                </div>
                            </styled-tooltip>
                        </div>
                    </td>
                    <td>
                        <span v-if="pinterestMetrics">{{ pinterestMetrics.stats.impressions.formatted }}</span>
                        <empty-field v-else />
                    </td>
                    <td>
                        <span v-if="pinterestMetrics">{{ pinterestMetrics.stats.cpm.formatted }}</span>
                        <empty-field v-else />
                    </td>
                    <td>
                        <span v-if="pinterestMetrics">{{ pinterestMetrics.stats.total_clickthrough.formatted }}</span>
                        <empty-field v-else />
                    </td>
                    <td>
                        <span v-if="pinterestMetrics">{{ pinterestMetrics.stats.total_clickthrough_cost_per.formatted }}</span>
                        <empty-field v-else />
                    </td>
                    <td>
                        <span v-if="pinterestMetrics">{{ pinterestMetrics.stats.total_lead.formatted }}</span>
                        <empty-field v-else />
                    </td>
                    <td>
                        <span v-if="pinterestMetrics">{{ pinterestMetrics.stats.total_lead_cost_per.formatted }}</span>
                        <empty-field v-else />
                    </td>
                    <td>
                        <action-button
                            icon="report-detailed"
                            :to="{ name: 'pinterest-dashboard', params: { dealer_id: dealer.id }}">
                            Show Details
                        </action-button>
                    </td>
                </tr>
            </table>
        </div>
        <powered-by
            v-if="!loading"
            :platforms="[
                dealerHasFacebook ? 'facebook' : '',
                dealerHasSnapchat ? 'snapchat' : '',
                dealerHasTiktok ? 'tiktok' : '',
                dealerHasPinterest ? 'pinterest' : ''
            ]" />
        <generate-report-slideout
            v-model="showGenerateReportSlideout"
            :template-id="FACEBOOK_REPORT_ID" />
    </styled-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import EmptyField from '@/components/globals/EmptyField';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import GenerateReportSlideout from '@/components/report-builder/GenerateReportSlideout';
import PoweredBy from '@/components/globals/PoweredBy';
import Loader from '@/components/globals/Loader';
import Funnel from './Funnel';
import Chart from './Chart';
import moment from 'moment';
import colors from '@/helpers/colors';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';
import { PLATFORM_PINTEREST, FACEBOOK_REPORT_ID } from '@/helpers/globals';

export default {
    name: 'AdvertisingStats',
    components: {
        StyledTooltip,
        Icon,
        EmptyField,
        Loader,
        StyledCard,
        ActionButton,
        StyledCheckbox,
        GenerateReportSlideout,
        PoweredBy,
        Funnel,
        Chart
    },
    data() {
        return {
            colors,
            metric: {},
            showGenerateReportSlideout: false,
            platformSelections: [],
            FACEBOOK_REPORT_ID,
            availableMetrics: [
                {
                    id: 'impressions',
                    name: 'Impressions',
                    value: 'impressions',
                    cost: {
                        key: 'cpm',
                        name: 'CPM'
                    },
                    spend: {
                        key: 'spend',
                        name: 'Spend'
                    }
                },
                {
                    id: 'shoppers',
                    name: 'Clicks',
                    value: 'clicks',
                    cost: {
                        key: 'cpc',
                        name: 'CPC'
                    },
                    spend: {
                        key: 'spend',
                        name: 'Spend'
                    },
                },
                {
                    id: 'leads',
                    name: 'Leads',
                    value: 'leads',
                    cost: {
                        key: 'cpl',
                        name: 'CPL'
                    },
                    spend: {
                        key: 'spend',
                        name: 'Spend'
                    },
                }

            ]
        };
    },
    computed: {
        ...mapState({
            platformsSelected: state => state.metrics.aggregate.platformsSelected,
            snapchatMetrics: state => state.metrics.snapchat.ads.metricsTotals,
            tiktokMetrics: state => state.metrics.tiktok.ads.metricsTotals,
            pinterestMetrics: state => state.metrics.pinterest.ads.metricsTotals,
            dateRange: state => state.metrics.dateRange,
            snapchatMetricsError: state => state.metrics.snapchat.ads.metricsError,
            facebookMetricsError: state => state.metrics.facebook.ads.dealerInsightsError,
            tiktokMetricsError: state => state.metrics.tiktok.ads.metricsError,
            pinterestMetricsError: state => state.metrics.pinterest.ads.metricsError,
            pinterestMetricsRunning: state => state.metrics.pinterest.ads.metricsRunning,
            dealer: state => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
        }),
        ...mapGetters({
            userIsClient: 'userIsClient',
            dealerFeatures: 'dealerFeatures',
            facebookMetrics: 'metrics/facebook/ads/metricsTotalsFormatted'
        }),
        ...mapGetters('metrics/snapchat/ads', {
            snapchatPreviousPeriodMetrics: 'hasPreviousPeriodMetrics'
        }),
        ...mapGetters('metrics/tiktok/ads', {
            tiktokPreviousPeriodMetrics: 'hasPreviousPeriodMetrics'
        }),
        ...mapGetters('metrics/pinterest/ads', {
            pinterestPreviousPeriodMetrics: 'hasPreviousPeriodMetrics'
        }),
        ...mapGetters('metrics/aggregate',[
            'loading',
            'metricsTotals',
            'metricsTotalsPreviousPeriod',
            'metricsTotalsPreviousMonth',
            'metricsTotalsPrevious90Days',
            'metricsIntervals'
        ]),
        errorPlatforms() {
            const platforms = [];

            // Determine if metrics errored out for a particular platform
            if(this.facebookMetricsError && this.platformsSelected.includes('facebook')) {
                platforms.push('Facebook');
            }
            if((this.snapchatMetricsError || !this.snapchatPreviousPeriodMetrics) && this.platformsSelected.includes('snapchat')) {
                platforms.push('Snapchat');
            }
            if((this.tiktokMetricsError || !this.tiktokPreviousPeriodMetrics) && this.platformsSelected.includes('tiktok')) {
                platforms.push('TikTok');
            }
            if((this.pinterestMetricsError || !this.pinterestPreviousPeriodMetrics) && this.platformsSelected.includes('pinterest') && !this.pinterestMetricsRunning.value) {
                platforms.push('Pinterest');
            }
            return platforms;
        },
        metrics() {
            return {
                average90Day: this.metricsTotalsPrevious90Days,
                previousMonth: this.metricsTotalsPreviousMonth,
                previousPeriod: this.metricsTotalsPreviousPeriod,
                selected: this.metricsTotals,
                series: this.metricsIntervals
            };
        },
        dealerHasFacebook() {
            return this.dealerFeatures.includes('facebook_ad_account');
        },
        dealerHasSnapchat() {
            return this.dealerFeatures.includes('snapchat_ad_account');
        },
        dealerHasTiktok() {
            return this.dealerFeatures.includes('tiktok_ad_account');
        },
        dealerHasPinterest() {
            return this.dealerFeatures.includes('pinterest_ad_account');
        },
        numberOfDays() {
            const end = moment(this.dateRange.endDate);
            const start = moment(this.dateRange.startDate);
            return end.diff(start, 'days');
        },
        metricsAvailableFacebook() {
            const facebookMetrics = this.facebookMetrics?.impressions ?? null;
            const facebookMetricsStatus = facebookMetrics ? true : false;
            return facebookMetricsStatus;
        },
        metricsAvailableTiktok() {
            const tiktokMetrics = this.tiktokMetrics?.stats?.impressions ?? null;
            const tiktokMetricsStatus = tiktokMetrics ? true : false;
            return tiktokMetricsStatus;
        },
        metricsAvailableSnapchat() {
            const snapchatMetrics = this.snapchatMetrics?.stats?.impressions ?? null;
            const snapchatMetricsStatus = snapchatMetrics ? true : false;
            return snapchatMetricsStatus;
        }

    },
    watch: {
        dealer() {
            this.platformSelections = [];
            this.setInitialPlatforms();
        },
        platformSelections(selections) {
            this.setPlatformsSelected(selections);
        }
    },
    mounted() {
        this.setInitialPlatforms();
        // Set default metric
        this.metric = this.availableMetrics[0];
    },
    methods: {
        ...mapActions('metrics/aggregate', [
            'setPlatformsSelected',
        ]),
        ...mapActions('metrics/pinterest/ads', {
            updateDealerPinMetrics: 'updateAllMetrics',
        }),
        async getPinterestStats() {
            await this.makeFullSync(PLATFORM_PINTEREST);
            await this.updateDealerPinMetrics();
        },
        async makeFullSync(channel) {
            try {
                await this.$apiRepository.queueDealerAdStatsSync({ dealerId: this.currentDealerId, platform: channel });
            } catch(error) {
                console.log(error);
            }
        },
        checkConnection() {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, { status: true, channel: PLATFORM_PINTEREST, dealer_id: this.currentDealerId });
        },
        setInitialPlatforms() {
            // Check to see what platform features the dealer has
            // and set the platformSelections to all available platforms
            if(this.dealerHasFacebook) {
                this.platformSelections.push('facebook');
            }
            if(this.dealerHasSnapchat) {
                this.platformSelections.push('snapchat');
            }
            if(this.dealerHasTiktok) {
                this.platformSelections.push('tiktok');
            }
            if(this.dealerHasPinterest) {
                this.platformSelections.push('pinterest');
            }
        },
        changeMetric(metric) {
            this.metric = metric;
        },
        checkSelectionsLength() {
            return this.platformSelections.length > 0;
        },
        togglePlatform(event) {
            const platform = event.target.value;
            const index = this.platformSelections.findIndex(p => p == platform);
            if(index > -1) {
                this.platformSelections.splice(index,1);
            } else {
                this.platformSelections.push(platform);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$breakpoint: 1330px;
.metrics {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint) {
        flex-direction: row;
    }

    .left-pane {
        display: flex;
        position: relative;
        flex-direction: column;
        border-bottom: 1px solid #d8d8d8;
        padding-top: 45px;
        flex: 1;
        width: 100%;

        @media (min-width: $breakpoint) {
            border-bottom: none;
            border-right: 1px solid #d8d8d8;
            width: 60%;
        }
    }
    .right-pane {
        width: 100%;
        @media (min-width: $breakpoint) {
            width: 40%;
        }
    }
}
.data-errors {
    padding: 15px;
    position: relative;
    background: $coral;
    .error {
        margin-bottom: 0;
        color: $black;
        background: transparent !important;
    }
    ul {
        margin: 0;
        list-style: none;
    }
    .platform {
        font-weight: 700;
        text-align: left;
        color: $black;
        margin-left: 15px;
    }
}
.platform-selections {
    display: flex;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 30px;
    z-index: 10;
    .platform-select {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        margin: 0 10px;
        &.inactive {
            opacity: 0.5;
            pointer-events: none;
        }
        label {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .icon {
            height: 20px;
            width: 20px;
            margin-bottom: 2px;
            position: relative;
            img {
                width: auto;
                height: 100%;
            }
        }
        .name {
            font-size: 12px;
            margin-bottom: 4px;
        }
    }
}
.channel-totals {
    width: 100%;
    border-top: 1px solid #d8d8d8;
}
.channel-table {
    border-collapse: collapse;
    width: 100%;
    tr {
        height: 55px;
    }
    td {
        border-bottom: 1px solid #d8d8d8;
    }
    .header {
        font-size: 12px;
        font-weight: 600;

        td {
            text-align: center;
        }
    }
    .channel {
        font-size: 13px;
        color: #3F4649;
        &:nth-of-type(2n+1) {
            background: #e6f6fd;
        }
        td {
            text-align: center;
        }
    }
}
.channel-warning {
    position: absolute;
    z-index: 9;
    background: white;
    border-radius: 50%;
    top: -7px;
    left: -10px;
}
.channel-name {
    position: relative;
}
.channel-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}
.channel-processing {
    display: flex;
    align-items: center;
    position: absolute;
    right: -60px;
    top: 50%;
    margin-top: -13px;
    white-space: nowrap;
    font-size: 10px;
    color: $gray;
    background: $gray-lightshade;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 9;
}
.channel-processing.failed {
    background: $light-error;
}
.info-icon {
    cursor: pointer;
}
.info-failed {
    display: flex;
    align-items: center;
}
</style>
