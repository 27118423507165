<template>
    <div class="styled-selector">
        <p
            v-if="label"
            class="label">
            {{ label }}:
        </p>
        <a
            v-outside-click="hideDropdown"
            href="#"
            class="selector"
            @click.prevent="showDropdown">
            {{ selectedItem.name }}
            <span class="dropdown-icon">
                <icon
                    name="dropdown"
                    color="#03a2ea"
                    size="12px" />
            </span>
            <ul
                v-show="showItems"
                class="item-list"
                :class="{'large-list':items.length > 5}">
                <li
                    v-for="(item,i) in items"
                    :key="i">
                    <a
                        href="#"
                        class="item"
                        @click.prevent.stop="selectItem(item)">{{ item.name }}</a>
                </li>
            </ul>
        </a>
    </div>
</template>

<script>
import Icon from './Icon';

export default {
    name: 'StyledSelector',
    components: {
        Icon
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        items: {
            // items should be an Array of objects like {name: 'something', value: 'something'}
            type: Array,
            required: true,
            validator: prop =>
                prop.every(
                    i =>  i.hasOwnProperty('name') && i.hasOwnProperty('value')
                )
        },
        value: {
            type: [String, Number],
            default: ''
        },
        returnValue: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            showItems: false,
            selectedItem: null
        };
    },
    watch: {
        value: {
            handler(value) {
                this.selectedItem = this.items.find(i => i.value == value) || this.items[0];
            },
            immediate: true
        }
    },
    methods: {
        selectItem(item) {
            this.selectedItem = this.items.find(i => i.value == item.value);
            this.$emit('input', this.returnValue ? item.value : item);
            this.showItems = false;
        },
        showDropdown() {
            this.showItems = true;
        },
        hideDropdown() {
            this.showItems = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.styled-selector {
    display: flex;
    flex-direction: row;
}
.selector {
    display: block;
    position: relative;
}
.label {
    margin-bottom: 0;
    margin-right: 5px;
}
.dropdown-icon {
    position: absolute;
    margin-left: 2px;
    top: -2px;
}
.item-list {
    position: absolute;
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    list-style-type: none;
    padding-left: 0;
    background: $white;
    border: 1px solid #d8d8d8;
    z-index: 10;
    &.large-list {
        height: 200px;
        overflow-y:auto;
    }

    .item {
        display: inline-block;
        padding: 10px 15px;
    }
}
</style>