<template>
    <div class="funnel-container">
        <div class="svg-container">
            <svg viewBox="0 0 532 465">
                <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd">
                    <g transform="translate(-117, -1095)">
                        <g transform="translate(59, 939)">
                            <g transform="translate(58, 156)">
                                <!-- Right Side Outlines -->
                                <path
                                    v-if="!hideSpend"
                                    d="M530.5,0.5 L530.5,74.5 L351.5,74.5 L351.5,0.5 L530.5,0.5 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M497.5,130.5 L497.5,204.5 L318.5,204.5 L318.5,130.5 L497.5,130.5 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M464.5,259.5 L464.5,333.5 L285.5,333.5 L285.5,259.5 L464.5,259.5 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M497.585561,205 L464.5,260 L497.585561,205 Z"
                                    stroke="#00A2EA" />
                                <path
                                    v-if="!hideSpend"
                                    d="M530.585561,75 L497.5,130 L530.585561,75 Z"
                                    stroke="#00A2EA" />


                                <!-- IMPRESSIONS -->
                                <g
                                    class="step"
                                    :class="{ 'active-step': metric.id == 'impressions' }"
                                    @click="selectMetric(availableMetrics[0])">
                                    <polygon
                                        class="step-bg"
                                        points="33 0 412 0 412 75 33 75" />
                                    <g class="step-text">
                                        <g
                                            transform="translate(141, 20)"
                                            font-family="OpenSans-Semibold, Open Sans">
                                            <text
                                                x="85"
                                                y="24"
                                                text-anchor="end">
                                                <tspan
                                                    font-size="14"
                                                    font-weight="500">IMPRESSIONS</tspan>
                                            </text>
                                            <text
                                                x="100"
                                                y="30"
                                                text-anchor="start">
                                                <tspan font-size="32">{{ metrics.impressions.formatted }}</tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>


                                <!-- SPEND -->
                                <g
                                    v-if="!hideSpend"
                                    transform="translate(432, 19)"
                                    font-family="OpenSans-Regular, Open Sans">
                                    <text
                                        x="40"
                                        y="12"
                                        fill="#4A4A4A"
                                        text-anchor="middle">
                                        <tspan
                                            font-size="11"
                                            font-weight="normal">SPEND</tspan>
                                    </text>
                                    <text
                                        text-anchor="middle"
                                        x="38"
                                        y="37"
                                        fill="#00A2EA">
                                        <tspan
                                            font-size="21"
                                            font-weight="500">{{ metrics.spend.formatted }}</tspan>
                                    </text>
                                </g>


                                <!-- CLICK THROUGH RATE -->
                                <polygon
                                    fill="#038AC6"
                                    points="33 75 412.066349 75 378.980788 130 65.4660968 130" />
                                <g
                                    font-family="OpenSans-Semibold, Open Sans"
                                    font-size="20"
                                    font-weight="500"
                                    fill="#FFFFFF">
                                    <text
                                        x="225"
                                        y="108"
                                        opacity="0.5"
                                        text-anchor="end">
                                        <tspan font-size="12">CLICK THROUGH RATE</tspan>
                                    </text>
                                    <text
                                        x="245"
                                        y="110"
                                        opacity="0.5"
                                        text-anchor="start">
                                        <tspan>{{ metrics.ctr.formatted }}</tspan>
                                    </text>
                                </g>


                                <!-- SHOPPERS -->
                                <g
                                    class="step"
                                    :class="{ 'active-step': metric.id == 'shoppers' }"
                                    @click="selectMetric(availableMetrics[1])">
                                    <g
                                        class="step-bg"
                                        transform="translate(66, 130)">
                                        <polygon points="0 0 313 0 313 75 0 75" />
                                    </g>
                                    <g
                                        class="step-text"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        font-weight="500">
                                        <g transform="translate(88, 149)">
                                            <text
                                                x="140"
                                                y="24"
                                                text-anchor="end">
                                                <tspan font-size="14">CLICKS</tspan>
                                            </text>
                                        </g>
                                        <text
                                            x="240"
                                            y="180"
                                            text-anchor="start">
                                            <tspan font-size="32">{{ metrics.clicks.formatted }}</tspan>
                                        </text>
                                    </g>
                                </g>


                                <!-- COST PER CLICK -->
                                <g
                                    v-if="!hideSpend"
                                    transform="translate(373, 142)"
                                    font-family="OpenSans-Regular, Open Sans">
                                    <text
                                        x="65"
                                        y="15"
                                        text-anchor="middle"
                                        font-weight="normal"
                                        fill="#4A4A4A">
                                        <tspan font-size="11">COST / CLICK</tspan>
                                    </text>
                                    <text
                                        x="62"
                                        y="40"
                                        text-anchor="middle"
                                        font-size="21"
                                        font-weight="500"
                                        fill="#00A2EA">
                                        <tspan>{{ metrics.cpc.formatted }}</tspan>
                                    </text>
                                </g>


                                <!-- CONVERSION RATE -->
                                <polygon
                                    fill="#038AC6"
                                    points="66 205 379.551658 205 346.466097 260 98.4660968 260" />
                                <text
                                    opacity="0.5"
                                    font-family="OpenSans-Semibold, Open Sans"
                                    font-size="12"
                                    font-weight="500"
                                    fill="#FFFFFF">
                                    <tspan
                                        x="227"
                                        y="229"
                                        text-anchor="end">CONVERSION</tspan>
                                    <tspan
                                        x="227"
                                        y="246"
                                        text-anchor="end">RATE</tspan>
                                </text>
                                <text
                                    opacity="0.5"
                                    font-family="OpenSans-Semibold, Open Sans"
                                    font-size="20"
                                    font-weight="500"
                                    fill="#FFFFFF">
                                    <tspan
                                        x="245"
                                        y="240"
                                        text-anchor="start">{{ metrics.conversion_rate.formatted }}</tspan>
                                </text>


                                <!-- LEADS -->
                                <g
                                    class="step"
                                    :class="{ 'active-step': metric.id == 'leads' }"
                                    @click="selectMetric(availableMetrics[2])">
                                    <polygon
                                        class="step-bg"
                                        points="99 260 346 260 346 335 99 335" />
                                    <g
                                        class="step-text"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        font-weight="500">
                                        <text
                                            x="227"
                                            y="302"
                                            text-anchor="end"
                                            font-size="14">
                                            <tspan>LEADS</tspan>
                                        </text>
                                        <text
                                            x="240"
                                            y="309"
                                            text-anchor="start"
                                            font-size="32">
                                            <tspan>{{ metrics.leads.formatted }}</tspan>
                                        </text>
                                    </g>
                                </g>


                                <!-- COST PER LEAD -->
                                <g
                                    v-if="!hideSpend"
                                    transform="translate(359, 275)">
                                    <text
                                        x="45"
                                        y="12"
                                        text-anchor="middle"
                                        font-family="OpenSans-Regular, Open Sans"
                                        fill="#4A4A4A">
                                        <tspan
                                            font-size="11"
                                            font-weight="normal">COST / LEAD</tspan>
                                    </text>
                                    <text
                                        x="45"
                                        y="37"
                                        text-anchor="middle"
                                        font-family="OpenSans-Semibold, Open Sans"
                                        fill="#00A2EA">
                                        <tspan
                                            font-size="21"
                                            font-weight="500">{{ metrics.cpl.formatted }}</tspan>
                                    </text>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div
            class="step-selection-indicator"
            :class="metric.id">
            <svg
                width="28px"
                height="48px"
                viewBox="0 0 28 48">
                <g
                    stroke="none"
                    stroke-width="1"
                    fill-rule="evenodd">
                    <g
                        transform="translate(-664, -803)"
                        fill="#ADF0FC">
                        <polygon
                            transform="translate(666.258622, 826.686292) rotate(-225) translate(-666.258622, -826.686292) "
                            points="648.258622 808.686292 684.258622 811.31242 650.88475 844.686292" />
                    </g>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Funnel',

    props: {
        metric: {
            type: Object,
            required: true
        },
        metrics: {
            type: Object,
            required: true
        },
        availableMetrics: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters('metrics', [
            'hideSpend'
        ]),
    },
    methods: {
        selectMetric(metric) {
            this.$emit('changeMetric', metric);
        },
    }
};
</script>

<style lang="scss" scoped>
$breakpoint: 1330px;
.funnel-container {
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .svg-container {
        margin-top: 40px;
        align-self: center;
        height: auto;
        width: 100%;
        max-width: 532px;
    }
    .step-selection-indicator {
        position: absolute;
        right: 60px;
        transition: top 0.2s linear;
        display:none;
        @media (min-width: $breakpoint) {
            &.impressions {
                display: block;
                top: 75px;
            }
            &.shoppers {
                display: block;
                top: 210px;
            }
            &.leads {
                display: block;
                top: 340px;
            }
        }
    }
    .step {
        .step-bg {
            fill: #00a2ea;
        }
        .step-text {
            fill: #ffffff;
        }
        &.active-step,
        &:hover {
            cursor: pointer;
            .step-bg {
                fill: #adf0fc;
            }
            .step-text {
                fill: #00a2ea;
            }
        }
    }
}
</style>