<template>
    <div>
        <div class="powered-by">
            <p class="label">
                Powered By:
            </p>
            <div class="platforms">
                <template v-for="channel in channels">
                    <styled-tooltip
                        v-if="platforms.includes(channel.name)"
                        :key="channel.name"
                        position="left">
                        <template #content>
                            {{ channel.tooltip }}
                        </template>
                        <p class="icon">
                            <img :src="channel.img">
                        </p>
                    </styled-tooltip>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        StyledTooltip
    },
    props: {
        platforms: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },
    computed: {
        channels() {
            return [
                {
                    name: 'facebook',
                    img: '/img/platforms/meta.svg',
                    tooltip: `${this.$brand.name} retrieves Meta data through their Insights APIs which offers additional functionality beyond what is available in the UI such as reporting on 1 day view and 7 day click attribution windows on website (pixel) events.  As a result data reported for Meta may differ from what is shown in Meta Ads Manager by default.`
                },
                {
                    name: 'snapchat',
                    img: '/img/platforms/snapchat.svg',
                    tooltip: `${this.$brand.name} retrieves Snapchat data through their Marketing API and retrieves data using the same parameters as their ad manager so most metrics should be identical; however, Snapchat does not supply account level metrics by default so BuyerBridge automatically computes that data from underlying campaigns.  As a result you may see differences in the data.`
                },
                {
                    name: 'microsoft',
                    img: '/img/platforms/microsoft.svg',
                    tooltip: ''
                },
                {
                    name: 'tiktok',
                    img: '/img/platforms/tiktok.svg',
                    tooltip: `${this.$brand.name} retrieves TikTok data through their Marketing API and retrieves data using the same parameters as their ads manager so most metrics should be identical. TikTok reports metrics using a 7-day post click and 1-day post view for its metrics and that is what is provided via their Marketing API.`
                },
                {
                    name: 'pinterest',
                    img: '/img/platforms/pinterest.svg',
                    tooltip: `${this.$brand.name} retrieves Pinterest data through their Marketing API and retrieves data using the same parameters as their ads manager so most metrics should be identical. Pinterest reports metrics using a 30-day post click and 30-day post view for its metrics and that is what is provided via their Marketing API.`
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>

.powered-by {
    display: flex;
    align-items: center;
    float: right;
    height: 75px;

    .label {
        display: block;
        margin: 0;
        padding: 5px;
        border-right: 1px solid #8f9eA6;
        font-size: 10px;
        text-transform: uppercase;
        color: #646f74;
        height: 24px;
    }
    .platforms {
        display: flex;
        margin: 10px;
        justify-content: space-around;
        align-items: center;

    }
    .icon {
        height: 20px;
        width: 20px;
        margin: 5px;
        cursor: help;
        img {
            height: auto;
            width: 100%;
        }
    }
}
</style>
