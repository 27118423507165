/* eslint-disable max-len */
import HttpClient from '@/http';
import moment from 'moment';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import { get } from 'lodash';

/**
 * This class is the primary engine for computing the budgeting
 * algorithm which is used to provide monthly pacing estimates
 * and budget suggestions to BuyerBridge users
 */
class BudgetingApi {

    /**
     * Primary constructor
     */
    constructor() {
        this.http = new HttpClient();
        this.numberFormats = getNumberFormats();

        // Setup timing variables
        this.now;

        this.startOfMonth;
        this.endOfMonth;
        this.yesterday;

        this.daysInMonth;
        this.daysPassedInMonth;
        this.daysRemainingInMonth;

        this.insights = [];
        this.todaysInsights = [];
    }

    /**
     * Retrieves all formatted budgeting insights for a given dealer
     *
     * @param {object} params
     */
    async getDealerInsights({ labels, dealer }) {

        this.calculateTiming();

        const startOfMonth = this.startOfMonth.format('YYYY-MM-DD');
        const today = this.now.format('YYYY-MM-DD');

        const [adAccountData, todaysData] = await Promise.all([
            this.getDealerAdAccountInsights({
                dealerId: dealer.id,
                startDate: startOfMonth,
                endDate: today,
                labels
            }),
            this.getDealerAdAccountInsights({
                dealerId: dealer.id,
                startDate: today,
                endDate: today,
                spendOnly: true,
                labels
            })
        ]);


        // Grab today's spend from the insights record
        const todaysInsights = get(todaysData, 'insights.data[0]', null) || {};

        return this.formatInsights({
            adAccountData,
            todaysInsights,
            dealer
        });
    }

    /**
     * Retrieves all formatted budgeting insights for a given agency
     *
     * @param {object} params
     */
    async getAgencyInsights({ labels, agencyId, dealers }) {
        const adAccounts = [];

        dealers.forEach(dealer => {
            const adAccount = this.getDealerAdAccount(dealer);

            if (adAccount) {
                adAccounts.push(adAccount);
            }
        });

        if (!adAccounts.length) {
            return;
        }

        this.calculateTiming();

        const startOfMonth = this.startOfMonth.format('YYYY-MM-DD');
        const today = this.now.format('YYYY-MM-DD');

        const [accountInsights, accountInsightsToday] = await Promise.all([
            this.getAgencyAdAccountInsights({
                startDate: startOfMonth,
                endDate: today,
                adAccounts,
                labels,
                agencyId
            }),
            this.getAgencyAdAccountInsights({
                startDate: today,
                endDate: today,
                spendOnly: true,
                adAccounts,
                labels,
                agencyId
            })
        ]);

        const formatData = (adAccountData) => {
            // Rejoin the dealer
            const dealer = dealers.find(dealer => dealer.id === adAccountData.dealer_id);

            // Join today's insights
            const todaysData = accountInsightsToday.find(todaysData => {
                return (adAccountData.id === todaysData.id);
            });

            // Grab today's spend from the insights record
            const todaysInsights = get(todaysData, 'insights.data[0]', null) || {};

            return this.formatInsights({
                adAccountData,
                todaysInsights,
                dealer
            });
        };

        return accountInsights.map(formatData);
    }

    /**
     * Updates timing calculations used throughout this class
     */
    calculateTiming() {
        this.now = moment();

        this.startOfMonth = this.now.clone().startOf('month');
        this.endOfMonth = this.now.clone().endOf('month');
        this.yesterday = this.now.clone().subtract(1, 'days');

        this.daysInMonth = this.now.daysInMonth();
        this.daysPassedInMonth = this.now.diff(this.startOfMonth, 'days');
        this.daysRemainingInMonth = this.daysInMonth - this.daysPassedInMonth;
    }

    /**
     * The primary API call to the Facebook dealer ad account proxy to retrieve
     * raw data
     *
     * @param {object} params
     */
    async getDealerAdAccountInsights({ startDate, endDate, spendOnly = false, labels, dealerId }) {

        const data = this.getInsightsQuery({ labels, startDate, endDate, spendOnly });

        const url = `/dealers/${dealerId}/graph-api/ad-account`;
        const response = await this.http.post(url, data);

        return response.data;
    }

    /**
     * The primary API call to the Facebook business ad account's proxy to retrieve
     * raw data on all provided ad accounts
     *
     * @param {object} params
     */
    async getAgencyAdAccountInsights({ startDate, endDate, spendOnly = false, adAccounts, labels, agencyId }) {
        const filtering = [{
            field: 'id',
            operator: 'IN',
            value: adAccounts
        }];

        const data = this.getInsightsQuery({ labels, startDate, endDate, spendOnly, filtering });

        const ownedAdAccountsUrl = `/agencies/${agencyId}/graph-api/business-manager/owned_ad_accounts`;
        const clientAdAccountsUrl = `/agencies/${agencyId}/graph-api/business-manager/client_ad_accounts`;

        const ownedAdAccountsResponse = await this.http.post(ownedAdAccountsUrl, data);
        const clientAdAccountsResponse = await this.http.post(clientAdAccountsUrl, data);

        const ownedAdAccountsData = ownedAdAccountsResponse?.data?.data || [];
        const clientAdAccountsData = clientAdAccountsResponse?.data?.data || [];

        return ownedAdAccountsData.concat(clientAdAccountsData);
    }

    /**
     * Builds an insights query for the single and multiple versions
     * of the insights proxy calls above
     *
     * @param {object} params
     */
    getInsightsQuery({ startDate, endDate, spendOnly = false, filtering = [], labels = [], }) {

        const insightsFiltering = [];
        const campaignFiltering = [];
        const limit = 1000;

        if (labels.length) {

            insightsFiltering.push({
                field: 'campaign.adlabels',
                operator: 'ANY',
                value: labels
            });

            campaignFiltering.push({
                field: 'adlabels',
                operator: 'ANY',
                value: labels
            });
        }

        campaignFiltering.push({
            field: 'effective_status',
            operator: 'IN',
            value: ['ACTIVE']
        });

        const timeRange = {
            since: startDate,
            until: endDate
        };


        let fields = [
            `insights.time_range(${JSON.stringify(timeRange)}).filtering(${JSON.stringify(insightsFiltering)}){spend}`
        ];

        // If we're not just retrieving spend grab the other metrics
        if (!spendOnly) {

            const campaignFields = [
                'id',
                'name',
                'daily_budget',
                'lifetime_budget',
                'budget_remaining',
                'bid_strategy',
                'buying_type',
                'spend_cap',
                'effective_status',
                'last_budget_toggling_time',
                'start_time',
                'stop_time'
            ];

            fields = [
                ...fields,
                ...[
                    'name',
                    'account_status',
                    'spend_cap',
                    'amount_spent',
                    `campaigns.limit(${limit}).filtering(${JSON.stringify(campaignFiltering)}){${campaignFields.join(',')}}`,
                ]
            ];
        }

        return {
            fields: fields.join(','),
            limit,
            filtering
        };
    }

    /**
     * Formats the retrieved insights data for external consumption
     *
     * @param {object} params
     */
    formatInsights({ adAccountData, dealer, todaysInsights }) {

        // Prep common data
        const spendCap = (adAccountData.spend_cap > 0) ? parseFloat(adAccountData.spend_cap / 100) : 0;
        const spendCapAmountSpent = (adAccountData.amount_spent > 0) ? parseFloat(adAccountData.amount_spent / 100) : 0;
        const campaigns = get(adAccountData, 'campaigns.data', null) || [];
        const insights = get(adAccountData, 'insights.data[0]', null) || {};
        const spendToDate = insights.spend ? parseFloat(insights.spend) : null;
        const monthlyBudget = dealer.budget ? parseFloat(dealer.budget) : null;
        const adAccount = adAccountData.id.replace('act_', '');
        const dailyBudget = this.getTotalDailyBudget(campaigns);
        const todaysSpend = todaysInsights.spend ? parseFloat(todaysInsights.spend) : 0;


        // Track the number of campaigns started inside the month
        const campaignsStartedInMonth = campaigns.filter(campaign => {
            return moment(campaign.start_time).isSame(this.now, 'month');
        });

        // Calculate stats based on the provided metrics
        const stats = this.calculateStats({
            monthlyBudget,
            spendToDate,
            todaysSpend,
            dailyBudget
        });

        return {
            adAccountId: adAccount,
            adAccountName: adAccountData.name,
            campaignsStartedInMonth: campaignsStartedInMonth.length,
            stats: this.formatStats(stats),
            campaigns,
            insights,
            dealer,
            spendCap,
            spendCapAmountSpent,
            adAccountData,
            todaysInsights
        };
    }

    /**
     * Retrieves the total daily budget for all running campaigns
     *
     * @param {array} campaigns
     */
    getTotalDailyBudget(campaigns) {
        let totalActiveDailyBudget = null;
        campaigns.forEach(campaign => {

            // Only active campaigns
            if (campaign.effective_status !== 'ACTIVE' || !campaign.daily_budget) {
                return;
            }

            const dailyBudget = parseInt(campaign.daily_budget);

            // If it's zero don't add it up - this way we can leave totalActiveDailyBudget
            // as null if no campaigns are running
            if (dailyBudget === 0) {
                return;
            }

            totalActiveDailyBudget += dailyBudget / 100; // Facebook sends whole numbers
        });
        return totalActiveDailyBudget;
    }

    /**
     * The primary function to compute the budgeting algorithms for
     * each ad account based on the timings and provided data
     *
     * @param {object} params
     */
    calculateStats({ monthlyBudget, spendToDate, dailyBudget, todaysSpend }) {

        // Calculate the spend up until yesterday (use 0 if we're on the first day)
        const spendToYesterday = (this.daysPassedInMonth == 0) ? 0 : (spendToDate - todaysSpend);

        // All values are required for calulations
        if (!monthlyBudget || !spendToDate || !dailyBudget) {
            return {
                monthlyBudget,
                spendToDate,
                dailyBudget,
                todaysSpend,
                spendToYesterday,
                // Null all computed metrics
                projectedSpend: null,
                averageDailyBudget: null,
                suggestedDailyBudget: null,
                suggestedDailyAdjustment: null,
                projectedSpendDifference: null
            };
        }

        // Calculate the projected spend based on yesteday's spend and the number of days left
        // here we assume that the daily budget will continue spending
        const projectedSpend = spendToYesterday + (dailyBudget * this.daysRemainingInMonth);

        // Calculate the average for user reference.  If we're on the 1st day just use today's budget.
        const averageDailyBudget = (this.fractionalDaysPassedInMonth >= 1) ? (spendToYesterday / this.fractionalDaysPassedInMonth) : dailyBudget;

        // Over "Suggested Daily Budget" (SDB) model - this is necessary because we've already spent
        // more than we were supposed to today so we need to stop spending today and decelerate the
        // budget going forward
        //
        // See original model explaining this here:
        // https://docs.google.com/spreadsheets/d/1L5s_s3WL9AJ5WJWe0F6dhWCoANrP4G_NJDFaBcKvMxo/edit#gid=824819648
        const daysRemainingMinusToday = this.daysRemainingInMonth - 1;
        const overSdbModelBudgetRemaining = monthlyBudget - spendToDate;
        const overSdbModelSuggestedDailyBudget = overSdbModelBudgetRemaining / daysRemainingMinusToday; // NaN on last day of month but not used

        // Underpacing (standard) model
        const defaultBudgetRemaining = monthlyBudget - spendToYesterday;
        const defaultDailyBudget = defaultBudgetRemaining / this.daysRemainingInMonth;

        let suggestedDailyBudget = null;
        let activeModel = null;

        // If we're not on the last day of the month and the overSdbModel budget remaining
        // is over today's spend use the overSdbModel model
        if ((daysRemainingMinusToday != 0) && (overSdbModelSuggestedDailyBudget < todaysSpend)) {
            activeModel = 'over_sdb_model';
            suggestedDailyBudget = overSdbModelSuggestedDailyBudget;
        } else {
            activeModel = 'default';
            suggestedDailyBudget = defaultDailyBudget;
        }

        // Calculate the necessary adjustment for user reference
        // this is useful for finding budgets that are far off from target
        const suggestedDailyAdjustment = suggestedDailyBudget - dailyBudget;


        const projectedSpendDifference = (projectedSpend - monthlyBudget) / projectedSpend;

        // Return a usable object for consumption
        return {
            monthlyBudget,
            projectedSpend,
            todaysSpend,
            spendToYesterday,
            spendToDate,
            dailyBudget,
            averageDailyBudget,
            suggestedDailyBudget,
            suggestedDailyAdjustment,
            activeModel,
            projectedSpendDifference
        };
    }

    /**
     * Helper function to format data coming from the Insights API
     * for external use
     *
     * @param {object} stats
     */
    formatStats(stats) {
        const newStats = {};

        let value;
        for (let stat in stats) {
            value = stats[stat];

            let formatted;

            if (typeof value == 'string') {
                formatted = value;
            } else if (stat == 'projectedSpendDifference') {
                formatted = (value !== null) ? numeral(value).format(this.numberFormats.percent) : null;
            } else {
                formatted = (value !== null) ? numeral(value).format(this.numberFormats.currency) : null;
            }

            newStats[stat] = { value, formatted };
        }

        return newStats;
    }

    /**
     * Helper to retrieve a dealers ad account
     *
     * @param {object} dealer
     */
    getDealerAdAccount(dealer) {
        return get(dealer, 'facebook_ad_account_annotations.data[0].facebook_ad_account_id', null);
    }
}

export default BudgetingApi;
