<template>
    <styled-card
        id="no-advertising-data"
        class="flex ma-3 xs12">
        <template #heading>
            Advertising Data Status
        </template>
        <template #action-buttons>
            <action-button
                icon="circle"
                color="red">
                Status: Missing Data
            </action-button>
        </template>
        <p class="message">
            There's no advertising data for this time range.  Possible culprits could be:
        </p>
        <table class="culprits">
            <thead>
                <tr>
                    <th>Issue</th>
                    <th>Actions needed if issue is current day/date</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>• The were no running campaigns during this time period</td>
                    <td>
                        <a
                            target="_blank"
                            :href="adAccountSettings">Check your campaigns</a>
                    </td>
                </tr>
                <tr>
                    <td>• There was a billing issue or a spend cap was met</td>
                    <td>
                        <a
                            target="_blank"
                            :href="adAccountBilling">Check your billing</a>
                    </td>
                </tr>
                <tr>
                    <td>• The audience was not large enough to produce ad spend</td>
                    <td>
                        <a
                            target="_blank"
                            :href="adAccountSettings">Check your audience</a>
                    </td>
                </tr>
                <tr>
                    <td>• Your Snapchat account is still in the process of being set up</td>
                    <td />
                </tr>
            </tbody>
        </table>
    </styled-card>
</template>

<script>
import { mapState } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import BudgetingApi from '@/budgeting-api';

export default {
    name: 'NoData',
    components: {
        StyledCard,
        ActionButton
    },
    data() {
        return {
            adAccount: null
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        adAccountSettings() {
                return `https://business.facebook.com/settings/ad-accounts/${this.adAccount}?business_id=${this.currentDealer.agency.facebook_business_id}`;
        },
        adAccountBilling() {
            return `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${this.adAccount}&business_id=${this.currentDealer.agency.facebook_business_id}&page=account_settings&tab=account_billing_settings`;
        }
    },
    mounted() {
        const budgetingApi = new BudgetingApi();
        this.adAccount = budgetingApi.getDealerAdAccount(this.currentDealer);
    }
};
</script>

<style lang="scss" scoped>
.message {
    display: inline-block;
    margin: 20px;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 600;
    color: $white;
    background: $carnation;
    text-transform: uppercase;
}
.culprits {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid darken($gray-light, 10%);
    margin-bottom: 30px;
    thead {
        border-bottom: 1px solid darken($gray-light, 10%);
    }
    th {
        padding: 5px 60px;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        color: darken($gray-light, 30%);
    }
    td {
        padding: 10px 60px;
    }
}
</style>