<template>
    <styled-card
        id="metric-comparison"
        class="flex ma-3 xs12">
        <template #heading>
            Compare Omni-Channel Metrics
        </template>
        <template #action-buttons>
            <p class="disclaimer">
                Comparison metrics are based on aggregate, unfiltered, totals for the channels selected at the top of this page
            </p>
        </template>
        <div class="pt-3">
            <v-layout
                row
                class="justify-center justify-center align-center">
                <div>
                    <v-select
                        v-model="leftSelectedValue"
                        :items="leftSelect"
                        label="Select"
                        outline
                        solo
                        flat
                        small
                        class="left-select metric-select" />
                </div>
                <div class="px-5 text-xs-center">
                    VS
                </div>
                <div>
                    <v-select
                        v-model="rightSelectedValue"
                        :items="rightSelect"
                        label="Select"
                        outline
                        solo
                        flat
                        small
                        class="right-select metric-select" />
                </div>
            </v-layout>
            <apexchart
                ref="chart"
                chart="line"
                :height="420"
                :options="options"
                :series="series" />
        </div>
        <powered-by
            :platforms="[
                dealerHasFacebook ? 'facebook' : '',
                dealerHasSnapchat ? 'snapchat' : '',
                dealerHasTiktok ? 'tiktok' : '',
                dealerHasPinterest ? 'pinterest' : ''
            ]" />
    </styled-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StyledCard from '@/components/globals/StyledCard';
import VueApexCharts from 'vue-apexcharts';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import PoweredBy from '@/components/globals/PoweredBy';

export default {
    name: 'MetricComparison',
    components: {
        StyledCard,
        PoweredBy,
        apexchart: VueApexCharts,
    },
    props: {
        leftMetric: {
            type: String,
            required: true
        },
        rightMetric: {
            type: String,
            required: true
        },
        metricsData: {
            type: Array,
            required: true
        },
        availableMetrics: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
            leftSelectedValue: '',
            rightSelectedValue: '',
            options: {
                chart: {
                    id: 'vuechart-example',
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dataLabels: {
                        enabled: true
                    },
                    height: 300
                },
                colors: ['#7FDDFE', '#E350B4'],
                xaxis: {
                    type: 'datetime',
                    //tickAmount: 3,
                    labels: {
                        trim: true,
                        //offsetX: 10,
                        style: {
                            colors: '#8f9ea6'
                        }
                    }
                },
                grid: {
                    strokeDashArray: 2,
                },
                stroke: {
                    width: 1
                },
                markers: {
                    size: 4,
                    colors: ['#FFFFFF', '#FFFFFF'],
                    strokeColors: ['#7FDDFE', '#E350B4'],
                    strokeWidth: 1,
                    fillOpacity: 0
                },
                legend: {
                    show: false
                },
                yaxis: [
                    {
                        decimalsInFloat: 2,
                        labels: {
                            style: {
                                color: '#8f9ea6'
                            }
                        }
                    },
                    {
                        opposite: true,
                        decimalsInFloat: 2,
                        labels: {
                            style: {
                                color: '#8f9ea6'
                            }
                        }
                    },

                ],
            },
            series: [
                {
                    name: 'REACH',
                    data: [
                        []
                    ]
                },
                {
                    name: 'IMPRESSIONS',
                    data: [
                        []
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters('metrics/facebook/ads', {
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
        }),
        ...mapGetters({
            dealerFeatures: 'dealerFeatures',
            hideSpend: 'metrics/hideSpend'
        }),
        availableSelections() {
            return this.availableMetrics.filter(item => {
                if (
                    item.tags?.includes('spend') &&
                    this.hideSpend
                ) {
                    return false;
                }

                if (
                    item.tags?.includes('offline_purchase') &&
                    !this.canViewOfflinePurchaseAttribution
                ) {
                    return false;
                }

                return true;
            });
        },
        leftSelect() {
            return this.availableSelections.map(item => {
                item.disabled = (item.value === this.rightSelectedValue);
                return item;
            });
        },
        rightSelect() {
            return this.availableSelections.map(item => {
                item.disabled = (item.value === this.leftSelectedValue);
                return item;
            });
        },
        dealerHasFacebook() {
            return this.dealerFeatures.includes('facebook_ad_account');
        },
        dealerHasSnapchat() {
            return this.dealerFeatures.includes('snapchat_ad_account');
        },
        dealerHasTiktok() {
            return this.dealerFeatures.includes('tiktok_ad_account');
        },
        dealerHasPinterest() {
            return this.dealerFeatures.includes('pinterest_ad_account');
        },
    },
    watch: {
        metricsData: {
            handler() {
                this.updateLeftSelectData();
                this.updateRightSelectData();
            },
            deep: true,
        },
        canViewOfflinePurchaseAttribution() {
            this.updateLeftSelectData();
            this.updateRightSelectData();
        },
        leftSelectedValue() {
            this.$emit('update', [this.leftSelectedValue, this.rightSelectedValue]);
            this.updateLeftSelectData();
        },
        rightSelectedValue() {
            this.$emit('update', [this.leftSelectedValue, this.rightSelectedValue]);
            this.updateRightSelectData();
        },
        leftMetric(value) {
            this.leftSelectedValue = value;
        },
        rightMetric(value) {
            this.rightSelectedValue = value;
        },
    },
    mounted() {
        this.leftSelectedValue = this.leftMetric;
        this.rightSelectedValue = this.rightMetric;
        // this.series[0].name = this.leftMetric.toUpperCase();
        // this.series[1].name = this.rightMetric.toUpperCase();

        this.updateLeftSelectData();
        this.updateRightSelectData();
    },
    methods: {
        updateLeftSelectData() {

            // Retrieve the selected field from the data
            const leftSeriesData = this.metricsData.map(item => {
                return [
                    new Date(item.start_date),
                    item.stats[this.leftSelectedValue].value
                ];
            });

            // Copy the original series into a new array so we
            // can force a state update in the chart
            const newSeries = [...this.series];
            newSeries[0] = {
                data: leftSeriesData,
                name: this.leftSelect.find(option => option.value == this.leftSelectedValue)?.text
            };

            this.series = newSeries;

            // Use the format specified in the first metric
            if (this.metricsData.length) {

                const firstMetric = this.metricsData[0].stats[this.leftSelectedValue].formatted;
                let format = 'number';
                if(firstMetric.includes('$')) {
                    format = 'currency';
                }
                if(firstMetric.includes('%')) {
                    format = 'percent';
                }
                if(firstMetric.includes(':')) {
                    format = 'time';
                }
                const newOptions = { ...this.options };
                newOptions.yaxis[0].labels.formatter = (value) => {
                    return numeral(value).format(this.numberFormats[format]);
                };
                this.options = newOptions;
            }
        },
        updateRightSelectData() {

            // Retrieve the selected field from the data
            const rightSeriesData = this.metricsData.map(item => {
                return [
                    new Date(item.start_date),
                    item.stats[this.rightSelectedValue].value
                ];
            });

            // Copy the original series into a new array so we
            // can force a state update in the chart
            const newSeries = [...this.series];
            newSeries[1] = {
                data: rightSeriesData,
                name: this.rightSelect.find(option => option.value == this.rightSelectedValue)?.text
            };

            this.series = newSeries;

            // Use the format specified in the first metric
            if (this.metricsData.length) {

                const firstMetric = this.metricsData[0].stats[this.rightSelectedValue].formatted;
                let format = 'number';
                if(firstMetric.includes('$')) {
                    format = 'currency';
                }
                if(firstMetric.includes('%')) {
                    format = 'percent';
                }
                if(firstMetric.includes(':')) {
                    format = 'time';
                }
                const newOptions = { ...this.options };
                newOptions.yaxis[1].labels.formatter = (value) => {
                    return numeral(value).format(this.numberFormats[format]);
                };
                this.options = newOptions;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.disclaimer {
    color: $gray-text;
}
</style>
